import { TopLevelContainer } from '@/modules/core/components/TopLevelContainer'
import dynamic from 'next/dynamic'
import React, { FC, Fragment } from 'react'
import Head from 'next/head'
import { HomePageProps } from '@/pages'
import { CarouselHero } from '@/ui/Banner/CarouselHero/CarouselHero'

// Reduce initial theHTML size, by rendering the bottom half of the page client side.
const BottomSection = dynamic(() => import('./bottom.section').then((m) => m.default), { ssr: false })

const TKHomePage: FC<HomePageProps> = ({ bannerData }) => {
  return (
    <Fragment>
      <Head>
        <link rel="canonical" href={`${process.env.APP_PAPER_HOST}${process.env.APP_BASEPATH}`} />
      </Head>
      <TopLevelContainer>
        {bannerData && <CarouselHero {...bannerData} />}
        <BottomSection />
      </TopLevelContainer>
    </Fragment>
  )
}

export default TKHomePage
